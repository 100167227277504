import React from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import HeroCard from "../components/HeroCard";
import CallToActionButton from "../components/CallToActionButton";
import Services from './../pages/Services';

const LearnContent = ({ data }) => {
  const { title, path, seoDescription } = data.learnContent.frontmatter;
  const { html } = data.learnContent;
  const { services } = data.services.frontmatter;

  return (
    <Layout bodyClass="page-learn-single">
      <SEO title={title} description={seoDescription} path={path} />
      <HeroCard title={title} />
      <Container className="content-container">
        <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
        <Services services={services} footer />
      </Container>
    </Layout>
  );
};

/* TODO it would be really nice to crop images (e.g. testimonials)
  based on settings in the JSON file
*/
export const query = graphql`
  query($id: String!) {
    services: markdownRemark(fileAbsolutePath: { regex: "/services.md/" }) {
      frontmatter {
        services {
          btnText
          subTitle
          title
          url
        }
      }
    }
    learnContent: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        category
        seoDescription
      }
      html
    }
  }
`;

export default LearnContent;
